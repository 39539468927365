// Third party
import React from "react";
import * as PropTypes from "prop-types";
import { graphql } from "gatsby";

// CONFIG
import { CONFIG } from "../config";

// Components
import Layout from "../components/Layout";
import Container from "../components/Container";
import Section from "../components/Section";
import Breadcrumb from "../components/Breadcrumb";
import Author from "../components/Author";
import SEO from "../components/seo";

class ArticleTemplate extends React.Component {
  render() {
    const {
      title,
      createdAt,
      updatedAt,
      slug,
      featuredImage: {
        file: { url: featuredImageUrl },
      },
      body: {
        childMarkdownRemark: { html, excerpt },
      },
      author,
    } = this.props.data.contentfulPost;

    const breadcrumbs = [
      {
        id: 1,
        title: "Home",
        slug: "/",
      },
      {
        id: 2,
        title: "Blog",
        slug: "/blog/",
      },
      {
        id: 3,
        title: title,
        slug: `/blog/${slug}`,
      },
    ];

    return (
      <Layout>
        <SEO
          title={`${title}`}
          description={excerpt}
          url={`${this.props.location.href}`}
          type={`article`}
          image={`${CONFIG.protocol}${featuredImageUrl}`}
          createdAt={createdAt}
          updatedAt={updatedAt}
        />

        {/* Shema.org tags */}

        <Breadcrumb items={breadcrumbs} />
        <Section>
          <Container>
            <div className="grid">
              <div className="grid__col grid__col--3-of-5 grid__col--centered">
                <h1>{title}</h1>
                <div dangerouslySetInnerHTML={{ __html: html }} />
                <Author {...author} date={createdAt} />
              </div>
            </div>
          </Container>
        </Section>
      </Layout>
    );
  }
}

ArticleTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ArticleTemplate;

export const postQuery = graphql`
  query postQuery($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      id
      title
      createdAt
      updatedAt
      slug
      featuredImage {
        file {
          url
        }
      }
      author {
        title
        body {
          childMarkdownRemark {
            excerpt
            html
          }
        }
        avatar {
          fixed(width: 126, height: 126) {
            src
          }
        }
      }
      body {
        childMarkdownRemark {
          html
          excerpt
        }
      }
    }
  }
`;
